import { postToSaleServer, postToItemServer, postToGovServer } from './common';
/**---------------------------设计超市----------------------------------
 * ⼯⼚设计超市版权购买列表 (老)
 * @param {*} opt
 */
export async function marketPriceList(opt) {
    let res = await postToItemServer("/design-market/list-price-product", opt);
    return res;
}
// ⼯⼚设计超市版权购买商品详情
export async function marketPriceId(id) {
    let item = await postToItemServer("/design-market/detail-price-product", { product_no: id });
    return item;
}
// ⼯⼚设计超市搜索版权购买列表
export async function searchProducts(opt) {
    let res = await postToItemServer("/design-mall/search-products", opt);
    return res;
}

/* 设计超市版权购买-列表单品 (新)
* @param {*} opt
*/
export async function marketListProduct(opt) {
    return await postToItemServer("/design-market/list-product", opt);
}
// 设计超市版权购买-单品详情
export async function marketDetailProduct(id) {
    return await postToItemServer("/design-market/detail-product", { id: id });
}
// 设计超市-工厂购买单品
export async function marketBuyProduct(product_no) {
    return await postToItemServer("/design-market/buy-product", { product_no: product_no });
}
// 设计超市版权购买-列表套系
export async function marketListSet(opt) {
    return await postToItemServer("/design-market/list-set", opt);
}
// 设计超市版权购买-套系详情
export async function marketDetailSet(id) {
    return await postToItemServer("/design-market/detail-set", { id: id });
}
// 设计超市-工厂购买套系
export async function marketBuySet(set_no) {
    return await postToItemServer("/design-market/buy-set", { set_no: set_no });
}

/**
 * ⼯⼚设计超市分成合作列表
 * @param {*} opt
 */
export async function marketPercentList(opt) {
    let res = await postToItemServer("/design-market/list-percent-product", opt);
    return res;
}
/**
 * ⼯⼚设计超市分成合作商品详情
 * @param {*} id
 */
export async function marketPercentId(id) {
    let item = await postToItemServer("/design-market/detail-percent-product", {
        product_no: id
    });
    return item;
}
/**---------------------------------工厂商品管理-------------------------------------------
 * 工厂版权销售列表(老)
 */
export async function facPriceList(opt) {
    return await postToItemServer("/factory/list-price-product", opt);
}
// 工厂版权销售列表-单品
export async function designProductOrder(opt) {
    return await postToItemServer("/factory/list-design-product-order", opt);
}
// 工厂版权销售列表-套系
export async function designSetOrder(opt) {
    return await postToItemServer("/factory/list-design-set-order", opt);
}
/**
 * 工厂版权销售详情
 * @param {*} id
 */
export async function facPriceId(id) {
    let item = await postToItemServer("/factory/detail-price-product", {
        trans_no: id
    });
    return item;
}
/**
 * 工厂分成合作列表
 */
export async function facPercentList(opt) {
    return await postToItemServer("/factory/list-percent-product", opt);
}
// 设计公司分成合作-终止合作
export async function endFacPercentProduct(obj) {
    return await postToItemServer('/factory/end-percent-product', obj);
}
/**
 * ⼯⼚分成合作中的商品详情
 * @param {*} id
 */
export async function factoryPercentId(id) {
    let item = await postToItemServer("/factory/detail-percent-product", {
        product_no: id
    });
    return item;
}
/**
 * 设置商品打样图片
 * @param {*} id 
 */
export async function setProductSampleImg(obj) {
    return await postToItemServer('/factory/set-product-sample-img', obj);
}
/**---------------------------自有商品----------------------------------
 * 工厂发布自有商品
 */
export async function addSelf(obj) {
    return await postToItemServer('/factory/add-self-product', obj);
}
/**
 * 工厂获取自有商品列表
 */
export async function getSelfList(opt) {
    return await postToItemServer('/factory/list-self-product', opt);
}
/**
 * 自定义分类编辑商品（工厂）
 * @param {*} obj
 */
export async function multiUpdateselfProduct(obj) {
    return await postToItemServer("/factory/multi-update-self-product", obj);
}
// 工厂自有商品上下架
export async function toggleSelfProduct(obj) {
    return await postToItemServer('/factory/toggle-market-self-product', obj);
}
// 工厂自有商品(销售手册)上下架
export async function toggleSalebookSelfProduct(obj) {
    return await postToItemServer('/factory/toggle-salebook-self-product', obj);
}
// 工厂自有商品修改
export async function setSelfProduct(obj) {
    return await postToItemServer('/factory/update-self-product', obj);
}
// 工厂自有商品删除
export async function delSelfProduct(opt) {
    return await postToItemServer('/factory/del-self-product', opt);
}
// 工厂自有商品删除恢复
export async function undelSelfProduct(obj) {
    return await postToItemServer('/factory/undel-self-product', obj);
}
/**
 * 工厂获取自有商品详情
 */
export async function getSelfId(id) {
    return await postToItemServer('/factory/detail-self-product', { product_no: id });
}
/**
 * 设置自有商品阶梯报价
 */
export async function setStepPrice(obj) {
    return await postToItemServer('/factory/set-product-step-price', obj);
}

/**--------------------------------工厂订单------------------------------------
 * 工厂订单列表
 * @param {} query 
 */
export async function toMesOrderList(query) {
    return await postToSaleServer(`/to_mes/order/list`, query);
}
/**
 * 工厂订单详情
 * @param {*} query 
 */
export async function toMesOrderById(id) {
    return await postToSaleServer(`/to_mes/order/get/${id}`);
}
/**--------------------------------政府------------------------------------
 * 产品规划送检申请
 */
export async function govProdPlan(obj) {
    return await postToGovServer('/apply_policy/prod_plan', obj);
}
/**
 * 金融产品申请
 */
export async function govFinance(obj) {
    return await postToGovServer('/apply_policy/finance', obj);
}
/**
 * 租金减免
 */
export async function govReductionRent(obj) {
    return await postToGovServer('/apply_policy/reduction_rent', obj);
}
/**
 * 企业退规
 */
export async function govQuit(obj) {
    return await postToGovServer('/apply_policy/quit', obj);
}
/**
 * 企业入规申请记录
 */
export async function applyList(obj) {
    return await postToGovServer('/front/apply/list', obj);
}
/**
 * 企业入规申请记录详情
 */
export async function getApplyId(id) {
    return await postToGovServer(`/front/apply/get/${id}`);
}
/**
 * 企业入规(新增/修改申请)
 */
export async function applySave(obj) {
    return await postToGovServer('/front/apply/save', obj);
}
/**
 * 政府申请记录
 * @param {} query 
 */
export async function govList(query) {
    return await postToGovServer(`/apply_policy/list`, query);
}
/**
 * 设计奖补(新增/修改申请)
 */
export async function applyAllowanceSave(obj) {
    return await postToGovServer('/front/sjjb/flow/save', obj);
}
/**
 * 设计奖补(提交草稿申请)
 */
export async function applyAllowanceDraftSave(id,obj) {
    return await postToGovServer(`/front/sjjb/flow/submit/${id}`,obj);
}
/**
 * 设计奖补申请记录
 * @param {} query 
 */
export async function designAllowanceList(query) {
    return await postToGovServer(`/front/sjjb/flow/list`, query);
}
/**
 * 设计奖补申请记录详情
 */
export async function getApplyAllowanceId(id) {
    return await postToGovServer(`/front/sjjb/flow/get/${id}`);
}
/**
 * 设计奖补申请记录流程节点设置
 */
export async function setApplyAllowanceFlow(opt) {
    return await postToGovServer(`/front/sjjb/flow/approve`, opt);
}
/**
 * 设计奖补审批后处理
 */
export async function setApplyedFlow(flow, opt) {
    return await postToGovServer(`/front/sjjb/set/${flow}`, opt);
}
